import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { Location } from "@reach/router"

const Header = ({ siteTitle, isFront }) => {
  const data = useStaticQuery(graphql`
    {
      allContentfulEastCoastPizzaHomeContent {
        edges {
          node {
            eastCoastHeaderImage {
              description
              fixed(height: 300) {
                ...GatsbyContentfulFixed_tracedSVG
              }
            }

            headerBackground {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const background =
    data.allContentfulEastCoastPizzaHomeContent.edges[0].node.headerBackground
      .fluid

  return (
    <Location>
      {({ navigate, location }) => (
        <BackgroundImage tag={"header"} fluid={background}>
          {console.log(location.pathname)}
          <Container
            style={{
              minHeight: location.pathname === "/" ? "80vh" : 0,
              paddingTop: 50,
            }}
          >
            <Row className="header_image d-flex justify-content-center">
              <Col
                xs="12"
                md="6"
                className="p-5 d-flex justify-content-center align-items-center"
              >
                {data.allContentfulEastCoastPizzaHomeContent.edges.map(edge => (
                  <Img
                    fixed={edge.node.eastCoastHeaderImage.fixed}
                    alt={edge.node.eastCoastHeaderImage.description}
                  />
                ))}
              </Col>
            </Row>
          </Container>
        </BackgroundImage>
      )}
    </Location>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isFront: PropTypes.bool,
}

Header.defaultProps = {
  siteTitle: ``,
  isFront: false,
}

export default Header
